<template>
  <div class="brand-manager">
    <div v-if="!brandData" class="empty-container">
      {{ isLoading ? "" : "暂无商家数据" }}
    </div>
    <Table v-else :columns="brandColumns" :data="brandData" border>
      <template slot-scope="{ row, index }" slot="action">
        <Button
          type="primary"
          size="small"
          style="margin: 2px"
          @click="addCount(row, index)"
          >添加数量</Button
        >
      </template>
    </Table>

    <Modal
      v-model="countAddModal"
      title="添加数量"
      @on-ok="onAddOk"
      @on-cancel="onAddCancel"
      :mask-closable="false"
    >
      <div style="width: 100%;padding: 20px;text-align:center;">
        <i-input v-model="countNum" placeholder="请输入数量" clearable 
        type="number" autofocus ></i-input>
      </div>
    </Modal>
  </div>
</template>

<script>
import api from "@/api";

export default {
  data() {
    return {
      isLoading: true,
      countAddModal:false,
      countNum: null,
      brandColumns: [
        {
          title: "商家名称",
          key: "name",
        },
        {
          title: "激活码剩余数量",
          key: "order_num",
        },
        {
          title: "操作",
          slot: "action",
          align: "center",
        },
      ],
      brandData: null,
      addIdx: -1,
    };
  },
  methods: {
    onAddOk(){
      if(!this.countNum || this.countNum <= 0){
        this.$Message.error("请输入数量");
        return ;
      }
      api
        .ordernumAdd(this.countNum, this.brandData[this.addIdx].id)
        .then((res) => {
          this.$Message.success(res.message);
          this.$log.debug(res);
          this.loadData();
        })
        .catch((err) => {
          this.$Message.error(err.message);
        });
      this.onAddCancel()
    },
    onAddCancel(){
      this.idnex = -1;
      this.countNum = null;
    },
    addCount(row, index) {
      this.$log.debug("row: ", row, ", index: ", index);
      this.addIdx = index;
      this.countAddModal=  true;
    },
    loadData() {
      api
        .merchantList()
        .then((res) => {
          this.$log.debug(res.merchants.toString());
          if (res.merchants && res.merchants.length > 0) {
            this.brandData = res.merchants;
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.$Message.error(err.message);
          this.$log.debug(err);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.brand-manager {
  width: 100%;
  padding: 12px;
  box-sizing: border-box;

  .empty-container {
    width: 100%;
    margin: 50px;
    text-align: center;
    font-size: 16px;
  }
}
</style>

